import React, { useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import styled from "styled-components";

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 400px;

  border: 1px solid rgba(29, 29, 27, 0.5);

  & .mapboxgl-control-container {
    display: none;
  }
`;

export const Map = ({ longitude, latitude }) => {
  const mapContainerRef = useRef(null);

  // initialize map when component mounts
  useEffect(() => {
    mapboxgl.accessToken = process.env.GATSBY_MAPBOX;

    if (typeof window !== undefined) {
      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        // See style options here: https://docs.mapbox.com/api/maps/#styles
        style: "mapbox://styles/dezbat/ckqs58lxi4xvd17mne6inzupx",
        center: [longitude, latitude],
        zoom: 12.5,
      });

      // add navigation control (the +/- zoom buttons)
      map.addControl(new mapboxgl.NavigationControl(), "bottom-right");

      // clean up on unmount
      return () => map.remove();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <MapContainer ref={mapContainerRef} />;
};
