import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageTypeConsumer } from "../components/context/page-type";

// Components
import { Mailchimp } from "../components/mailchimp/mailchimp";
import { Map } from "../components/map/map";

const Container = styled.div`
  width: 100%;
  max-width: 1400px;

  padding: 190px 100px 0 100px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    padding: 190px 50px 0 50px;
  }

  @media (max-width: 768px) {
    padding: 100px 0 0 0;
  }
`;

const Intro = styled.div`
  width: 100%;

  margin: 0 auto;

  & .text {
    width: 100%;
    max-width: 900px;
    text-align: center;

    padding: 80px 0;
    margin: 0 auto;

    font-size: 36px;
    line-height: 36px;
    letter-spacing: 0.01em;

    & p:first-of-type {
      margin-top: 0;
    }

    & p:last-of-type {
      margin-bottom: 0;
    }

    @media (max-width: 1300px) {
      font-size: 28px;
      line-height: 36px;
    }

    @media (max-width: 768px) {
      padding: 40px 20px 0 20px;

      font-size: 24px;
      line-height: 27px;
    }
  }

  @media (max-width: 768px) {
    padding: 0 0 45px 0;
  }
`;

const MapContainer = styled.div`
  margin: 60px auto 50px auto;

  & .map-text {
    width: 100%;
    text-align: center;

    padding: 0;

    font-size: 15px;
    line-height: 130%;
    letter-spacing: 0.04em;

    @media (max-width: 1300px) {
      font-size: 14px;
      line-height: 18px;
    }

    @media (max-width: 1080px) {
      padding: 20px;
    }
  }
`;

const ContactBlockContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  width: 100%;
  max-width: 1060px;

  margin: 0 auto;
`;

const ContactBlock = styled.div`
  &.contact-block-1,
  &.contact-block-2,
  &.contact-block-5 {
    width: 100%;

    & li {
      flex: 33.333%;
      max-width: 33.333%;

      @media (max-width: 600px) {
        width: 100%;
        flex: 100%;
        max-width: 100%;
      }
    }
  }

  &.contact-block-3,
  &.contact-block-4 {
    width: 33.333%;

    @media (max-width: 600px) {
      width: 100%;
      flex: 100%;
      max-width: 100%;
    }
  }

  @media (max-width: 600px) {
    &.contact-block-5 {
      order: 1;
    }
    &.contact-block-1 {
      order: 2;
    }
    &.contact-block-2 {
      order: 3;
    }
    &.contact-block-3 {
      order: 4;
    }
    &.contact-block-4 {
      order: 5;
    }
    &.contact-block-6 {
      order: 6;
    }
  }

  text-align: center;

  & ol {
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;

    @media (max-width: 600px) {
      display: block;
    }

    & li {
      @media (max-width: 600px) {
        width: 100%;
        flex: 100%;
        max-width: 100%;
      }
    }
  }

  & .section-title {
    margin: 0 0 20px 0;

    & h2 {
      font-family: inherit;
      text-transform: uppercase;

      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.08em;
      font-variant: small-caps;
      font-weight: 500;
    }
  }

  & .name {
    & h4 {
      font-size: 36px;
      line-height: 36px;
      letter-spacing: 0.01em;

      &:hover {
        color: #df3f1c;
      }

      @media (max-width: 1300px) {
        font-size: 28px;
        line-height: 36px;
      }

      @media (max-width: 900px) {
        font-size: 26px;
        line-height: 30px;
      }
    }
  }

  & .details {
    margin: 0 0 60px 0;

    & p {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.04em;
    }
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  margin: 50px auto 0 auto;

  & .section-title {
    width: 100%;
    margin: 0 0 25px 0;

    text-align: center;

    & p {
      font-size: 36px;
      line-height: 40px;
      letter-spacing: 0.01em;

      margin: 0;

      @media (max-width: 1300px) {
        font-size: 28px;
        line-height: 36px;
      }

      @media (max-width: 900px) {
        font-size: 26px;
        line-height: 30px;
      }
    }
  }

  & .single-link {
    padding: 0 30px;

    & p {
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.12em;
      text-transform: uppercase;
    }

    & button {
      padding: 1em 0;
      line-height: 1;

      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      color: #1d1d1b;
    }
  }
`;

const MailchimpContainer = styled.div``;

const ContactContent = ({ data, setPageType }) => {
  const [isNewsletterVisible, setIsNewsletterVisible] = useState(false);

  useEffect(() => {
    setPageType("contact");
  }, []);

  const content = data.prismicContact.data.body.map((content, index) => {
    if (content.slice_type === "section") {
      const items = content.items.map((item, index) => (
        <li key={`${content.id}_${index}`}>
          <div
            className="name"
            dangerouslySetInnerHTML={{
              __html: item.name.html,
            }}
          />
          <div
            className="details caslon-doric"
            dangerouslySetInnerHTML={{
              __html: item.details.html,
            }}
          />
        </li>
      ));

      return (
        <ContactBlock key={content.id} className={`contact-block-${index + 1}`}>
          <div
            className="section-title caslon-doric"
            dangerouslySetInnerHTML={{
              __html: content.primary.section_title.html,
            }}
          />

          <ol>{items}</ol>
        </ContactBlock>
      );
    }
  });

  const links = data.prismicContact.data.links.map((content, index) => (
    <div
      className="single-link caslon-doric"
      key={`single_link_${index}`}
      dangerouslySetInnerHTML={{
        __html: content.link.html,
      }}
    />
  ));

  return (
    <>
      <Helmet
        title={`${data.prismicContact.data.title.text} – Park Village`}
        meta={[
          {
            name: "og:title",
            content: `${data.prismicContact.data.title.text} – Park Village`,
          },
          {
            name: "twitter:title",
            content: `${data.prismicContact.data.title.text} – Park Village`,
          },
        ]}
      />

      <Container>
        <Intro>
          {data.prismicContact.data.image.fluid !== null && (
            <img
              srcSet={data.prismicContact.data.image.fluid.srcSetWebp}
              src={data.prismicContact.data.image.fluid.srcWebp}
              alt={data.prismicContact.data.image.alt}
              loading="lazy"
            />
          )}
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: data.prismicContact.data.text.html,
            }}
          />
        </Intro>

        <ContactBlockContainer>{content}</ContactBlockContainer>

        <LinksContainer>
          <div
            className="section-title"
            dangerouslySetInnerHTML={{
              __html: data.prismicContact.data.link_text.html,
            }}
          />

          {links[0]}
          <div className="single-link caslon-doric">
            <button
              onClick={() => setIsNewsletterVisible(!isNewsletterVisible)}
            >
              Newsletter
            </button>
          </div>
          {links[1]}
        </LinksContainer>

        {isNewsletterVisible && (
          <MailchimpContainer>
            <Mailchimp setIsNewsletterVisible={setIsNewsletterVisible} />
          </MailchimpContainer>
        )}

        <MapContainer>
          <Map
            latitude={data.prismicContact.data.location.latitude}
            longitude={data.prismicContact.data.location.longitude}
          />
          <div
            className="map-text caslon-doric"
            dangerouslySetInnerHTML={{
              __html: data.prismicContact.data.map_text.html,
            }}
          />
        </MapContainer>
      </Container>
    </>
  );
};

const Contact = ({ data }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <ContactContent setPageType={setPageType} data={data} />
    )}
  </PageTypeConsumer>
);

export default withPreview(Contact);

export const query = graphql`
  {
    prismicContact {
      data {
        title {
          text
        }
        image {
          fluid {
            srcWebp
            srcSetWebp
          }
          alt
        }
        text {
          html
        }
        link_text {
          html
        }
        links {
          link {
            html
          }
        }
        location {
          latitude
          longitude
        }
        map_text {
          html
        }
        body {
          ... on PrismicContactBodySection {
            id
            slice_type
            primary {
              section_title {
                html
              }
            }
            items {
              name {
                html
              }

              details {
                html
              }
            }
          }
        }
      }
    }
  }
`;
