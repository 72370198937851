import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import addToMailchimp from "gatsby-plugin-mailchimp";

const EnquiryFormContainer = styled.div`
  position: relative;
  align-self: flex-end;

  order: 12;

  @media (max-width: 1140px) {
    order: 1;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  & .signup-text {
    margin: 30px 0 15px 0;

    text-align: center;
    color: #1d1d1b 50%;

    & p {
      margin: 0;

      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.04em;

      color: #1d1d1b 50%;
    }
  }

  & .thank-you-message,
  & form {
    max-width: 900px;
    margin: 0 auto;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;

    & .top-fields {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    & .interests {
      width: 100%;
      margin: 0 0 1em 0;

      & p {
        font-size: 15px;
        line-height: 18px;

        text-align: center;
      }

      & ul {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        & li {
          margin: 0 10px;

          & input {
            margin: 0 5px 0 0;
          }

          & label {
            font-size: 15px;
            line-height: 18px;
          }
        }
      }
    }

    & .field {
      position: relative;
      z-index: 1;

      border: 1px solid #1d1d1b;
      background-color: #fff;

      margin: 0 5px;

      &.company {
        width: 200px;

        & input {
          width: 178px;
        }

        @media (max-width: 600px) {
          width: 100%;

          & input {
            width: auto;
          }
        }
      }

      &.name {
        width: 140px;

        & input {
          width: 118px;
        }

        @media (max-width: 600px) {
          width: 100%;

          & input {
            width: auto;
          }
        }
      }

      @media (max-width: 600px) {
        width: 100%;

        margin: 0 20px 20px 20px;

        & input {
          width: auto;
        }
      }

      & input {
        padding: 5px 10px 3px 10px;
        margin: 0;
        border: 0;

        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.04em;

        font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande",
          sans-serif;

        ::-webkit-input-placeholder {
          font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande",
            sans-serif;
          color: #1d1d1b 50% !important;
        }
        ::-moz-placeholder {
          font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande",
            sans-serif;
          color: #1d1d1b 50% !important;
        }
        :-ms-input-placeholder {
          font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande",
            sans-serif;
          color: #1d1d1b 50% !important;
        }
        :-moz-placeholder {
          font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande",
            sans-serif;
          color: #1d1d1b 50% !important;
        }
      }
    }
  }

  & .thank-you-message {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.04em;
  }
`;

const SubmitButtonContainer = styled.div`
  margin: 0 5px;

  @media (max-width: 600px) {
    margin: 0 20px;

    width: 100%;
  }

  & .top-line-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;

    & p {
      margin: 0;
    }

    & button {
      background-color: #108caf;

      text-align: center;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.12em;
      font-weight: 600;

      padding: 5px 20px 5px 20px;

      transition: 150ms all ease;
      color: #fff;

      @media (max-width: 600px) {
        width: 100%;
      }
    }
  }
`;

const ErrorsContainer = styled.div`
  width: 100%;

  margin: 15px 0;

  text-align: center;

  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.08em;

  opacity: ${props => props.opacity};
  transition: 150ms opacity ease;
`;

export const Mailchimp = ({ setIsNewsletterVisible }) => {
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [formMessage, setFormMessage] = useState(null);
  const [mailchimpMessage, setMailchimpMessage] = useState(null);

  const data = useStaticQuery(graphql`
    {
      prismicContact {
        data {
          signup_text {
            html
          }
          signup_confirmation_text {
            html
          }
        }
      }
    }
  `);

  // Form Setup
  const { register, handleSubmit, errors } = useForm();

  // Form checkbox options
  const [studios, setStudios] = useState(false);
  const [film, setFilm] = useState(false);
  const [photography, setPhotography] = useState(false);
  const [experiential, setExperiential] = useState(false);
  const [all, setAll] = useState(true);

  const onSubmit = (data, e) => {
    e.preventDefault();

    addToMailchimp(data.email, {
      FNAME: data.fName,
      LNAME: data.lName,
      MMERGE3: data.company,
      ...(studios && { "group[39026][1]": "1" }),
      ...(film && { "group[39026][2]": "2" }),
      ...(photography && { "group[39026][4]": "4" }),
      ...(experiential && { "group[39026][8]": "8" }),
      ...(all && { "group[39026][16]": "16" }),
    }) // listFields are optional if you are only capturing the email address.
      .then(data => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        if (data.result === "error") {
          if (data.msg === "The email you entered is not valid.") {
            setFormMessage("Invalid Email");
          }

          if (data.msg.includes("already subscribed")) {
            setMailchimpMessage(
              "Thank you for signing up to the Park Village mailing list."
            );
            setIsFormVisible(false);
            e.target.reset();
          }
        } else {
          setMailchimpMessage(
            "Thank you for signing up to the Park Village mailing list."
          );
          setIsFormVisible(false);
          e.target.reset();
        }
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      });
  };

  useEffect(() => {
    if (isFormVisible === false) {
      const fadeOutForm = setTimeout(() => {
        const resetFormVisbility = setTimeout(() => {
          setIsFormVisible(true);
          setIsNewsletterVisible(false);
        }, 1000);
        return () => clearTimeout(resetFormVisbility);
      }, 8000);
      return () => clearTimeout(fadeOutForm);
    }
  }, [isFormVisible]);

  return (
    <EnquiryFormContainer className="z-index-100">
      {isFormVisible && (
        <>
          <div
            className="signup-text caslon-doric"
            dangerouslySetInnerHTML={{
              __html: data.prismicContact.data.signup_text.html,
            }}
          />
          <form
            name="signup-form"
            id="holding_site_signup_form"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="signup-form" />

            <div className="top-fields">
              <div className="field name">
                <input
                  id="fName"
                  name="fName"
                  type="fName"
                  autoComplete="given-name"
                  aria-required="true"
                  aria-label="First Name"
                  placeholder={
                    errors.fName ? `This field is required` : `First name`
                  }
                  ref={register({
                    required: true,
                  })}
                />
              </div>

              <div className="field name">
                <input
                  id="lName"
                  name="lName"
                  type="text"
                  autoComplete="family-name"
                  aria-required="true"
                  aria-label="Last Name"
                  placeholder={
                    errors.lName ? `This field is required` : `Last name`
                  }
                  ref={register({
                    required: true,
                  })}
                />
              </div>

              <div className="field company">
                <input
                  id="mce-MMERGE3"
                  name="company"
                  type="text"
                  aria-label="Company"
                  placeholder={`Your company or industry`}
                  ref={register}
                />
              </div>

              <div className="field email">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  aria-required="true"
                  aria-label="E-mail"
                  placeholder={
                    errors.email
                      ? `Enter a valid email address`
                      : `Your email address`
                  }
                  ref={register({
                    required: true,
                  })}
                />
              </div>
            </div>

            <div className="interests caslon-doric">
              <p>Interested in</p>

              <ul>
                <li>
                  <input
                    type="checkbox"
                    value="1"
                    name="studios"
                    id="mce-group[39026]-39026-0"
                    ref={register}
                    checked={studios === true}
                    onChange={() => {
                      if (studios === true) {
                        setStudios(false);
                      } else {
                        setStudios(true);
                      }
                    }}
                  />
                  <label htmlFor="mce-group[39026]-39026-0">Studios</label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    value="2"
                    name="group[39026][2]"
                    id="mce-group[39026]-39026-1"
                    ref={register}
                    checked={film === true}
                    onChange={() => {
                      if (film === true) {
                        setFilm(false);
                      } else {
                        setFilm(true);
                      }
                    }}
                  />
                  <label htmlFor="mce-group[39026]-39026-1">Film</label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    value="4"
                    name="group[39026][4]"
                    id="mce-group[39026]-39026-2"
                    ref={register}
                    checked={photography === true}
                    onChange={() => {
                      if (photography === true) {
                        setPhotography(false);
                      } else {
                        setPhotography(true);
                      }
                    }}
                  />
                  <label htmlFor="mce-group[39026]-39026-2">Photography</label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    value="8"
                    name="group[39026][8]"
                    id="mce-group[39026]-39026-3"
                    ref={register}
                    checked={experiential === true}
                    onChange={() => {
                      if (experiential === true) {
                        setExperiential(false);
                      } else {
                        setExperiential(true);
                      }
                    }}
                  />
                  <label htmlFor="mce-group[39026]-39026-3">
                    Experiential &amp; Events
                  </label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    value="16"
                    name="group[39026][16]"
                    id="mce-group[39026]-39026-4"
                    ref={register}
                    checked={all === true}
                    onChange={() => {
                      if (all === true) {
                        setAll(false);
                      } else {
                        setAll(true);
                      }
                    }}
                  />
                  <label htmlFor="mce-group[39026]-39026-4">All news</label>
                </li>
              </ul>
            </div>

            <SubmitButtonContainer>
              <div className="top-line-container">
                <button
                  type="submit"
                  className="submit-button caslon-doric"
                  tabIndex="0"
                >
                  Submit
                </button>
              </div>
            </SubmitButtonContainer>

            <ErrorsContainer
              className="caslon-doric"
              opacity={errors.fName || errors.lName || errors.email ? 1 : 0}
            >
              <p>Please complete the missing information</p>
            </ErrorsContainer>
          </form>
        </>
      )}

      {!isFormVisible && (
        <div className="thank-you-message caslon-doric" id="thank_you_message">
          <div
            dangerouslySetInnerHTML={{
              __html: data.prismicContact.data.signup_confirmation_text.html,
            }}
          />
        </div>
      )}
    </EnquiryFormContainer>
  );
};
